import * as React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../components/header';
import Footer from '../components/footer';
import '../styles/main.less';

// markup
const ImpressumPage = () => {
  return (
      <div>
        <Helmet htmlAttributes={{lang: 'de'}}>
          <meta charSet="utf-8"/>
          <title>Impressum | Kinderosteopathie </title>
          <link rel="canonical" href="https://kinderosteopathie.bayern/impressum"/>
        </Helmet>

        <Header/>

        <main className={'main main--impressum'}>
          <section className="section section--impressum">
            <div className="section__content">

              <h1 class="heading heading--section">
                Impressum
              </h1>

              <p class={'section__paragraph'}>
                <b>Angaben gem. § 5 TMG:</b><br/>
                Heilpraktikerin Karin Eideloth<br/>
                Herzog-Wilhelm-Str. 25<br/>
                80331 München<br/>
                Telefon: 0151 684 351 59<br/>
                E-Mail: <a href={"mailto:info@psychoaktive-osteopathie.de"}>info@psychoaktive-osteopathie.de</a><br/>

              </p>
              <p className={'section__paragraph'}>
                <b>Umsatzsteuer-Identifikationsnummer gem. § 27 a Umsatzsteuergesetz: nach § 4, Nr. 14 a UStG von Umsatzsteuer befreit
                </b>

              </p>

              <p className={'section__paragraph'}>
                Referat für Gesundheit München<br/>
                Bayerstr. 28a<br/>
                80335 München
              </p>

            </div>
          </section>

        </main>
        <Footer/>
      </div>
  );
};

export default ImpressumPage;
